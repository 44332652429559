.legend {
    background-color: rgba(255, 255, 255, 0.8) !important; /* White transparent background */
    color: rgb(1, 99, 172) !important; /* Text color */
    padding: 20px;
  }
  
  .legend h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .legend p {
    font-size: 16px;
    color: #717275 !important;
    margin-bottom: 10px;
  }
  
  .legend button {
    background-color:  rgb(1, 99, 172);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 30px;
    cursor: pointer;
  }
  
  .legend button:hover {
    background-color: #597081;
  }

  /* Carousal.css */
.carousel-container {
  width: 800px; /* Set your desired width */
  height: 500px; /* Set your desired height */
  overflow: hidden; /* Ensure content doesn't overflow */
  margin: 0 auto; /* Center the carousel */
}

.carousel-container img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure images cover the container without stretching */
}

.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
}
  