
@media (max-width: 992px) {
    .goalPic {
      max-width: 100%;
    }
  }

  @media (min-width: 990px) {
    .goalPic {
      max-width: 900px;
    }
  }
