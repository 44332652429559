.navbar {
    background-color: rgb(1, 99, 172);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 20;
  }
  
  .nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    max-width: 1500px;
  }

  .logo-image {
    width: 200px;
    height: auto;
    padding: 10px;
  }
  
  .nav-logo {
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
    flex-grow: 1;
  }
  
  .nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
    margin-right: 16rem;
  }
  
  .dropdown {
    position: relative;
  }
  
  .dropdown-content {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    background-color: #f9f9f9;
    color: black !important;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  .dropdown-link {
    color: #333 !important;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-link:hover {
    background-color: #f1f1f1;
  }
  .nav-links {
    color: #fff;
    text-decoration: none;
    padding: 0.5rem 0.001rem;
    height: 100%;
    border-bottom: 3px solid transparent;
  }
  /* .fa-code {
    margin-left: 0.01rem;
  }
   */
  .nav-item {
    line-height: 40px;
    margin-right: 0.001rem;
  }
  
  .nav-item:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
  }
  
  .nav-item:hover:after {
    width: 100%;
    background: white;
  }
  
  .nav-item .active {
    color: black;
    /* border: 1px solid white; */
  }
  
  .nav-icon {
    display: none;
  }
  .nav-usericon {
    color: white;
  }

  .button{
    background: rgb(1, 99, 172);
    border: 2px solid white;
    color: white;
    border-radius: 600px;
    margin-top: 10px;
    margin-bottom: 5px;
    padding-bottom: 10px;
  }

  .button:hover{
    background: white;
    border: 2px solid white;
    color: rgb(1, 99, 172);
    border-radius: 400px;
    margin-bottom: 10px;
    padding-bottom: 20px;
  }
  
  @media screen and (max-width: 960px) {
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-top: 1pxsolid #fff;
      position: absolute;
      top: 80px;
      left: -110%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: rgb(1, 99, 172);
      left: 0px;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
    .nav-item .active {
      color: black;
      border: none;
    }
    .nav-links {
      padding: 0.001rem;
      width: 100%;
      display: table;
    }
  
    .nav-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
      color: white;
    }
  }