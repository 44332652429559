.main-container {
    display: flex;
  }
  
  .content-container {
    flex: 1;
    padding: 10px;
    margin-left: 250px; /* Adjust the margin to match the width of the sidebar */
  }
  
  .custom-table {
    width: 100%;
  }
  
  .custom-table th,
  .custom-table td {
    padding: 6px;
    text-align: left;
  }
  
  .custom-table th {
    background-color: rgb(1, 99, 172);
    color: white;
    border:1px solid rgb(1, 99, 172);
  }
  
  .no-users {
    text-align: center;
  }

  button{
    background-color: rgb(1, 99, 172) !important;
    color: white !important;
  }