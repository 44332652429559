* {
  margin: 0;
  padding: 0;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  cursor: pointer;
  background: rgb(1,99,172);
  background-blend-mode: darken;
  background-size: cover;
}

.navbar ul {
  width: 70%;
  display: flex;
  align-items: center;
}

.navbar li {
  list-style: none;
  padding: 26px 30px;
}

.navbar a {
  text-decoration: none;
  color: white;
}

.navbar a:hover {
  color: grey;
}



.button {
  background-color: white;
  color: rgb(1, 99, 172);
  border: 2px solid rgb(1, 99, 172);
  font-weight: bold;
}

.button:hover {
  background-color: rgb(1, 99, 172);
  color: white;
}
